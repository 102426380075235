import axios from "axios";
import { errorMessageFormat } from "./errorMessageFormat";
import { STORAGE_KEYS } from "../constants/storageKeys";

export const errorFormatHandler = (err) => {
  const errorObject = {
    status: err.response?.status,
    message: errorMessageFormat(err),
    errorApi: err?.message ?? err.error?.message,
    statusText: err.response?.statusText,
    ok: err.response?.data?.ok,
  };

  return errorObject;
};

export const endpointStatus = {
  fulfilled: "fulfilled",
  pending: "pending",
  rejected: "rejected",
  notFound: "notFound",
};

export const checkEndpointStatus = (endpointResult, endpointCheckFunction) => {
  if (endpointCheckFunction.fulfilled.match(endpointResult)) {
    return endpointStatus.fulfilled;
  } else if (endpointCheckFunction.rejected.match(endpointResult)) {
    return endpointStatus.rejected;
  } else if (endpointCheckFunction.pending.match(endpointResult)) {
    return endpointStatus.pending;
  }
  return endpointStatus.notFound;
};

const axiosService = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const localStorageLanguage =
  localStorage.getItem(STORAGE_KEYS.SELECTED_LANGUAGE) ?? "en";

export const apiRequest = async (
  url,
  method = "GET",
  transferObject,
  customHeader
) => {
  const token = localStorage.getItem("token");
  const Authorization = token ? `bearer ${token}` : "";

  return await axiosService.request({
    url,
    method,
    data: method !== "GET" ? transferObject : undefined,
    params: method === "GET" ? transferObject : undefined,
    timeout: 0,
    headers: {
      Authorization,
      "Content-Type": "application/json",
      Accept: "application/json",
      Language: localStorageLanguage,
      ...customHeader,
    },
  });
};
