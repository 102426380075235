export const approachSoftwareConfig = [
  {
    name: "approach_analyze_title",
    description: "approach_analyze_description",
    icon: "/assets/SSNTAssets/ApproachIcons/discovery.svg",
  },
  {
    name: "approach_planing_title",
    description: "approach_planing_description",
    icon: "/assets/SSNTAssets/ApproachIcons/planning.svg",
  },
  {
    name: "approach_design_title",
    description: "approach_design_description",
    icon: "/assets/SSNTAssets/ApproachIcons/design.svg",
  },
  {
    name: "approach_development_title",
    description: "approach_development_description",
    icon: "/assets/SSNTAssets/ApproachIcons/development.svg",
  },
  {
    name: "approach_qa_title",
    description: "approach_qa_description",
    icon: "/assets/SSNTAssets/ApproachIcons/quality-assurance.svg",
  },
  {
    name: "approach_deployment_title",
    description: "approach_deployment_description",
    icon: "/assets/SSNTAssets/ApproachIcons/deployment.svg",
  },
  {
    name: "approach_support_title",
    description: "approach_support_description",
    icon: "/assets/SSNTAssets/ApproachIcons/support.svg",
  },
  {
    name: "approach_reach_out_title",
    icon: "/assets/SSNTAssets/ApproachIcons/join-team.svg",
    buttonText: "approach_reach_out_button",
    buttonUrl: "career",
  },
];
