export const clientConfig = {
  email: "office@ssnt.ro",
  addressLocation:
    "Str. Eustatiu Stoenescu, nr.1C, bl.T7, Craiova, Dolj, Romania, 200618",

  orderNumber: "J16/347/2020",
  CUI: "RO42287516",
  mapsUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d713.7710931303353!2d23.785279569685105!3d44.30821595787708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4752d79684889ce7%3A0xc5f9786496a207b6!2sStrada%20Vrancei%2074%2C%20Craiova!5e0!3m2!1sro!2sro!4v1722334289073!5m2!1sro!2sro",
};
