import React from "react";
import KTSVG from "../utils/KTSVG";
import { colorsConfig } from "../configs/colorsConfig";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

function OurWorkCard({
  icon,
  workTitle,
  description,
  className,
  onClick,
  page,
  category,
  cardWhiteTextColor,
}) {
  const { t: translate } = useTranslation();

  return (
    <Link
      to={`${page}?category=${category}`}
      onClick={onClick}
      className={`${className} ${
        cardWhiteTextColor ? "text-white" : "text-black"
      } flex flex-col  justify-start items-start lg:hover:scale-105 transition ease-in-out duration-300 pt-[50px] px-[30px] pb-[30px] mb-3 
       cursor-pointer select-none lg:mx-1.5 shadow-lg hover:shadow rounded-md`}
    >
      <KTSVG
        path={icon}
        fill={`${cardWhiteTextColor ? colorsConfig.white : colorsConfig.black}`}
        svgClassName="w-[50px] h-[50px] "
      />
      <h5
        className={`${cardWhiteTextColor} font-bold text-[20px] drop-shadow-sm mt-3 mb-5`}
      >
        {workTitle}
      </h5>
      {description && (
        <p className="font-semibold drop-shadow mb-[100px]">{description}</p>
      )}

      <p className="flex flex-row  justify-center items-center font-semibold text-xs hover:ml-2 transition-all ease-in-out duration-200">
        <FaArrowRightLong className="mr-1 mb-0.5" />
        {translate("work_card_all_about_text")} {workTitle}
      </p>
    </Link>
  );
}

export default OurWorkCard;
